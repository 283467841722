<template>
  <div>
    <a-spin :spinning="loading">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item>
          <router-link :to="{ name: 'agent_monthly_bill_info', params: { agent_monthly_bill_id: billId }}">
            {{ agentRenewOrder.month }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ agentRenewOrder.no }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-descriptions
        :column="3"
        class="simiot-descriptions simiot-descriptions-max-10"
      >
        <a-descriptions-item label="订单批次">
          {{ agentRenewOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="续期周期数">
          {{ agentRenewOrder.cycles | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentRenewOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="订单时间">
          {{ agentRenewOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="卡号数量">
          {{ agentRenewOrder.cards_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="订单金额(元)">
          {{ agentRenewOrder.total_fee | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="佣金金额(元)">
          {{ agentRenewOrder.commission_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>

    <search-sim-card-renew-order @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loadingSimCardRenewOrders"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <span slot="product_price_title">
        <a-tooltip>
          <template slot="title">
            这里显示的是套餐周期单价，精确到两位小数
          </template>
          套餐价格(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchSimCardRenewOrders"
    />
  </div>
</template>

<script>
import { findAgentRenewOrder, findSimCardRenewOrders, exportSimCardRenewOrders } from '@/api/agent_renew_order'
import SearchSimCardRenewOrder from '@/views/agent_monthly_bills_commission/renew/show/Search'
import Pagination from '@/components/Pagination'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowAgentRenewOrder',
  components: {
    Pagination,
    SearchSimCardRenewOrder
  },
  data() {
    return {
      agentRenewOrder: {},
      loading: true,
      loadingSimCardRenewOrders: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      },
      data: []
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '客户套餐',
          width: 220,
          dataIndex: 'agents_product_name'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '续期周期数',
          dataIndex: 'cycles'
        },
        {
          title: '续期金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        },
        {
          title: '佣金金额(元)',
          dataIndex: 'commission_fee',
          customRender: formatCurrency
        }
      ]
    },

    agentRenewOrderId() {
      return parseInt(this.$route.params.renew_order_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
    this.fetchSimCardRenewOrders()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchSimCardRenewOrders()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchSimCardRenewOrders()
    },

    fetchData() {
      this.loading = true
      findAgentRenewOrder(this.agentRenewOrderId).then((res) => {
        if (res.code === 0) {
          this.agentRenewOrder = res.data
        }
        this.loading = false
      })
    },

    fetchSimCardRenewOrders() {
      this.loadingSimCardRenewOrders = true
      findSimCardRenewOrders(this.agentRenewOrderId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loadingSimCardRenewOrders = false
      })
    },

    exportData() {
      exportSimCardRenewOrders(this.agentRenewOrderId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
